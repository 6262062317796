<template>
   <section class="cont not-check">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>组织管理</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <!-- 主体内容 -->
      <el-row class="content-flex-box">
         <!-- 侧边表格 -->
         <el-col :span="6" class="left-table-bg">
            <el-row class="left-table">
               <el-table
                     ref="sideTable"
                     v-loading="sideLoad"
                     :data="sideData"
                     highlight-current-row
                     @row-click="handleCurrentChange"
                     row-key="id"
                     :load="loadCompanyChild"
                     lazy size="medium"
                     border fit
                     max-height="780"
                     :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                     :stripe="true">
                  <el-table-column prop="companyName" :label="$t('msg.tenant_store')"></el-table-column>
               </el-table>
            </el-row>
         </el-col>
         <!-- 主表格 -->
         <el-col :span="18" class="right-table-bg">
            <el-row class="right-table">
               <!-- 表格搜索框 -->
               <el-row class="search-box">
                  <el-row class="search-row">
                     <el-row class="search-item">
                        <label v-text="$t('msg.org_name_a')"></label>
                        <el-input class="width-220" :placeholder="$t('msg.org_name_code')"  v-model="orgNameCode" clearable></el-input>
                     </el-row>
                     <el-button class="bg-gradient" type="primary"  @click="handleQuery()"><span v-text="$t('msg.search')">搜索</span></el-button>
                     <el-button type="primary"  plain @click="handleReset()"><span v-text="$t('msg.reset')">重置</span></el-button>
                  </el-row>
               </el-row>
               <!-- 表格组件 -->
               <el-row class="table-box">
                  <div class="table-head-btn">
                     <el-button-group >
                        <el-button  :class="type === '' ? 'bg-gradient' : ''" @click="handleTab('')" v-text="$t('msg.whole')"></el-button>
                        <el-button  :class="type === 'PLATFORM' ? 'bg-gradient' : ''" @click="handleTab('PLATFORM')"><span v-text="$t('msg.platform')">平台</span></el-button>
                        <el-button  :class="type === 'TENANT' ? 'bg-gradient' : ''" @click="handleTab('TENANT')" v-text="$t('msg.tenant')"></el-button>
                        <el-button  :class="type === 'STORE' ? 'bg-gradient' : ''" @click="handleTab('STORE')" v-text="$t('msg.store')"></el-button>
                     </el-button-group>
                     <el-row class="right-btn">
                        <el-button class="bg-gradient" type="primary"  @click="handleAdd">新建组织</el-button>
                     </el-row>
                  </div>
                  <el-table
                        ref="singleTable"
                        v-loading="loading"
                        :data="tableData"
                        highlight-current-row
                        @row-click="handleTableChange"
                        row-key="id"
                        lazy size="medium"
                        border fit
                        :load="loadOrgChild"
                        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                        :stripe="true">
                     <el-table-column prop="name" :label="$t('msg.org_name')" min-width="120"></el-table-column>
                     <el-table-column prop="number" :label="$t('msg.org_coding')" min-width="120"></el-table-column>
                     <el-table-column :label="$t('msg.org_type')">
                        <template slot-scope="scope">
                           <p>{{scope.row.type | filterType}}</p>
                        </template>
                     </el-table-column>
                     <el-table-column prop="companyName" :label="$t('msg.tenant_store')" min-width="140"></el-table-column>
                     <el-table-column prop="hotelName" label="所属酒店" min-width="140"></el-table-column>
                     <el-table-column prop="createTime" :label="$t('msg.create_time')" width="200"></el-table-column>
                     <el-table-column :label="$t('msg.operating')" min-width="90" fixed="right">
                        <template slot-scope="scope">
                           <el-button @click.stop="handleEdit(scope.row)" type="text">
                              <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                           </el-button>
                           <el-button @click.stop="handleDel(scope.row.id, scope.row.parentId)" type="text" style="color: #F56C6C;">
                              <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                           </el-button>
                        </template>
                     </el-table-column>
                  </el-table>
               </el-row>
               <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
            </el-row>
         </el-col>
      </el-row>
      <!-- 添加/编辑弹窗 -->
      <el-dialog :title="action === 'add' ? '新建组织' : '编辑组织'" :visible.sync="isShow"  :before-close="handleClose" width="500px">
         <el-form class="form-dialog-box">
            <el-form-item label="组织类型：">
               <span v-text="orgType"></span>
            </el-form-item>
            <el-form-item label="租户名称：" v-show="tenantIsShow">
               <span v-text="tenantName"></span>
            </el-form-item>
            <el-form-item label="商户名称：" v-show="storeIsShow">
               <span v-text="storeName"></span>
            </el-form-item>
            <el-form-item label="创建智能语音部：" v-if="orgType !== '平台' && action === 'add'">
               <el-switch  v-model="isCreateVoice"
                           class="width-300"
                           active-color="#67c23a"
                           inactive-color="#dcdfe6"
                           :active-value="true"
                           :inactive-value="false"
                           @change="handleSwitch">
               </el-switch>
            </el-form-item>
            <el-form-item label="酒店名称：" v-show="hotelIsShow" :required="isRequired">
               <el-select class="width-300" v-if="!hotelName" v-model="hotelId" @change="handleChange" clearable :placehoder="$t('msg.client')">
                  <el-option
                        v-for="item in hotelList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                  </el-option>
               </el-select>
               <span v-else>{{hotelName}}</span>
            </el-form-item>
            <el-form-item label="组织名称：" v-if="!hideNameCode" required>
               <el-input  class="width-300" v-model="name" :placeholder="$t('msg.org_name')" :disabled="isDisabled" clearable></el-input>
            </el-form-item>
            <el-form-item label="组织编码："  v-if="!hideNameCode" required>
               <el-input  class="width-300" v-model="number" :placeholder="$t('msg.org_coding')" :disabled="isDisabled" clearable></el-input>
            </el-form-item>
            <el-form-item label="备注信息：">
               <el-input   class="width-300 type-textarea" type="textarea" v-model="remark" :placeholder="$t('msg.inp_remark_info')"></el-input>
            </el-form-item>
         </el-form>
         <span slot="footer" class="dialog-footer">
            <el-button  @click="resetForm()" v-text="$t('msg.reset')">重置</el-button>
            <el-button class="bg-gradient" type="primary" @click="handleSave()" v-text="$t('msg.save')">保存</el-button>
         </span>
      </el-dialog>
   </section>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import { system } from '@/api/interface/system'
   import { CustomArray } from '@/common/js/common'
   export default {
      data(){
         return{
            tableData: [],        // 表格列表
            sideData: [],         // 侧边列表数据
            hotelList: [],        // 酒店列表
            maps: new Map(),      // 表格数子节点
            sideLoad: true,       // 侧边栏开启加载效果
            loading: true,        // 主体表格开启加载效果
            boxIcon: true,        // 侧边栏折叠状态
            isShow: false,        // 是否显示弹窗
            tenantIsShow: false,  // 租户是否显示
            storeIsShow: false,   // 商户是否显示
            hotelIsShow: false,   // 酒店是否显示
            hideNameCode: false,  // 隐藏名称、编码
            tenantName: "",       // 租户名称
            storeName: "",        // 商户名称
            orgType: '平台',      // 组织类型
            total: 0,             // 总条目数
            action: 'add',        // 操作行为
            parentId: '',         // 子节点父id
            hotelName: '',        // 酒店名称
            isRequired: false,    // 是否必填
            isDisabled: false,    // 是否禁用
            oid: '',
            cid: '',
            /* 查询参数 */
            page: 1,              // 当前页 默认第1页
            limit: 0,             // 每页显示数
            level: 1,             // 结构树层级
            orgNameCode: "",      // 组织名称/编码共用字段
            type: '',             // 组织类型 用于tab栏查询
            // companyId: '',     // 用于点击侧边栏查询
            /* 添加表单字段 */
            id: '',               // 组织id
            hotelId: '',          // 酒店ID
            name: '',             // 组织名称
            number: '',           // 组织编码
            companyId: '',        // 单位ID
            isCreateVoice: false, // 是否创建语音部
            remark: '',           // 备注
            /* 弹窗提示文本 */
            hotelId_no_null: '酒店不能为空！',
            name_no_null: "名称不能为空！",
            number_no_null: "组织编码不能为空！",
            add_success: "添加成功！",
            del_success: "删除成功！",
            update_success: "修改成功！",
            confirm: '确定',
            cancel: '取消',
            confirm_remove: '确定移除？',
            prompt: '提示！'
         }
      },
      mounted() {
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.getOrg()
         this.getCompanies()
      },
      methods: {
         // 获取组织列表
         getOrg(){
            const param = {
               page: this.page,
               limit: this.limit,
               level: this.level,
               orgNameCode: this.orgNameCode,
               type: this.type,
               companyId: this.companyId
            }
            const url = system.orgPaging
            this.$axios.post(url, param).then((res) => {
               if(res.success){
                  this.total = res.total
                  this.loading = false
                  let tableData = res.records
                  let customArray = new CustomArray(tableData)
                  this.tableData = customArray.changeKey('hasChildren', 'leaf')
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取组织子节点列表
         getOrgChild(param){
            return new Promise((resolve, reject) => {
               const url = system.org
               let promise = this.$axios.post(url, param, 'json')
               promise.then(res => {
                  resolve(res)
               }).catch(error => {
                  reject(error)
               })
            })
         },
         // 懒加载组织子节点列表
         async loadOrgChild(tree, treeNode, resolve){
            // 将当前子节点数据存储到map中
            this.maps.set(tree.id, { tree, treeNode, resolve })
            const param = {
               parentId: tree.id,
               level: tree.level + 1
            }
            const tableChildData = await this.getOrgChild(param)
            if (tableChildData.success){
               let customArray = new CustomArray(tableChildData.children)
               const newData = customArray.changeKey('hasChildren', 'leaf')
               resolve(newData)
            }
         },
         // 重新获取当前子节点列表
         refreshLoadTree(parentId) {
            // 根据父级id取出对应节点数据
            if (this.maps.get(parentId)){
               const { tree, treeNode, resolve } = this.maps.get(parentId)
               this.$set(this.$refs.singleTable.store.states.lazyTreeNodeMap, parentId, [])
               if (tree) {
                  this.loadOrgChild(tree, treeNode, resolve)
               }
            }else {
               this.getOrg()
            }
         },
         // 获取租户/商户列表
         getCompanies(){
            const url = system.companies
            let param = {}
            let { accountType } = JSON.parse(sessionStorage.getItem('userInfo'))
            if (accountType === 'PLATFORM' ||
               accountType === 'TENANT') param.type = 'TENANT'
            if (accountType === 'STORE') param.type = 'STORE'
            this.$axios.post(url, param, 'json').then(res => {
               if(res.success){
                  this.sideLoad = false
                  let sideData = res.records
                  let customArray = new CustomArray(sideData)
                  this.sideData = customArray.changeKey('hasChildren', 'leaf')
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 懒加载租户/商户子节点列表
         loadCompanyChild(tree, treeNode, resolve){
            const url = system.companies
            const param = { tenantId: tree.id }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success){
                  let data = res.records
                  data.forEach(item => item.companyLogo && (item.companyLogo = window.getResourcesHost() + item.companyLogo))
                  resolve(data)
               }
            })
         },
         // 搜索功能
         handleQuery(){
            this.level = null // 搜索时不能限制level,否则查询不到子节点
            this.getOrg()
         },
         // 重置搜索
         handleReset(){
            this.level = 1
            this.orgNameCode = ''
            this.getOrg()
         },
         // tab栏切换
         handleTab(type){
            this.type = type
            this.companyId = ''
            this.$refs.sideTable.setCurrentRow()
            this.getOrg()
         },
         // 改变显示条数
         pageChange(num){
            this.limit = num
            this.getOrg()
         },
         // 改变当前页
         handlePaging(num){
            this.page = num
            this.getOrg()
         },
         // 获取侧边栏当前点击对象
         handleCurrentChange(val){
            if (val.id !== this.cid){
               // 用于添加表单
               this.companyId = val.id
               // 用于侧边栏切换
               // this.companyId = val.id
               this.getOrg()
               // 动态加载弹窗文本
               this.textToggle(val)
               // 保存选中行id
               this.cid = val.id
               return
            }
            // 取消选中 重置属性
            this.companyId = ''
            this.cid = ''
            this.$refs.sideTable.setCurrentRow()
            this.textToggle('')
            this.getOrg()
         },
         handleTableChange(val){
            if (val.id !== this.oid){
               this.companyId = val.companyId
               this.id = val.id
               this.hotelName = val.hotelName
               this.hotelId = val.hotelId
               this.parentId = val.id
               this.textToggle(val)
               this.oid = val.id
               return
            }
            // 取消选中 重置属性
            // 如果侧边栏未选中
            if (!this.cid) this.companyId = ''; this.textToggle('')
            this.oid = ''
            this.id = ''
            this.hotelId = ''
            this.hotelName = ''
            this.$refs.singleTable.setCurrentRow();
         },
         // 对弹窗的文本进行显示/隐藏
         textToggle(val){
            if (val && val.type !== 'PLATFORM'){
               this.hotelIsShow = true
            }
            if (val.type === "TENANT"){
               this.orgType = '租户'
               this.tenantName = val.companyName // 显示弹窗的租户名称
               this.tenantIsShow = true
               this.storeName = ""    // 隐藏弹窗的商户名称
               this.storeIsShow = false
               return;
            }
            if (val.type === "STORE"){
               this.orgType = '商户'
               this.tenantName = ""
               this.tenantIsShow = false  // 显示弹窗的租户名称
               this.storeName= val.companyName  // 显示弹窗的商户名称
               this.storeIsShow = true
               return
            }
            this.orgType = '平台'
            this.hotelIsShow = false
            this.tenantIsShow = false
            this.storeIsShow = false
         },
         // 获取酒店列表
         getHotelList(){
            const url = urlObj.getHotelList
            const param = { storeId: this.companyId }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success){
                  let hotelList = res.records
                  this.hotelList = hotelList.map(item => ({label: item.hotelName, value: item.id}))
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 选中当前酒店
         handleChange(val){
            /*
            * 如果是新建根组织
            * 且勾选了智能语音部
            * 则默认组织名称和酒店名称相同
            * */
            if (!this.isRequired) return
            let obj = []
            obj = this.hotelList.filter(item => (item.value === val))
            this.name = obj[0].label
         },
         // 切换智能语音部创建状态
         handleSwitch(){
            /*
            * 如果是新建根组织
            * 且勾选了智能语音部
            * 则酒店框为必填项
            * */
            if (!this.oid && this.isCreateVoice) {
               this.isRequired = true
            }else {
               this.isRequired = false
            }
            /*
            * 如果组织已关联酒店
            * 且勾选了智能语音部
            * 则隐藏组织名称、编码输入框
            * */
            if (this.hotelName && this.isCreateVoice) {
               this.hideNameCode = true
            }else {
               this.hideNameCode = false
            }
         },
         // 添加组织
         handleAdd(){
            this.isShow = true
            this.action = 'add'
            this.getHotelList()
         },
         // 编辑组织
         handleEdit(row){
            // 开启弹窗
            this.isShow = true
            this.action = 'edit'
            // 如果编辑对象是智能语音部
            if (row.number === 'voice') this.isDisabled = true
            // 设置弹窗表单值
            this.name = row.name
            this.number = row.number
            this.hotelId = row.hotelId
            // 如果对象层级非根 则取出酒店名称且禁止修改酒店
            row.level > 1 ? this.hotelName = row.hotelName : this.getHotelList()
            this.remark = row.remark
            this.parentId = row.parentId
            this.id = row.id
            // 动态加载弹窗文本
            this.textToggle(row)
         },
         // 清空表单
         resetForm(){
            this.hotelId = ''
            this.name = ''
            this.number = ''
            this.isCreateVoice = false
            this.remark = ''
         },
         // 保存组织
         handleSave(){
            // 酒店不能为空
            if (this.isRequired && !this.hotelId){
               this.$alert(this.hotelId_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            // 当未隐藏名称时 名称不能为空
            if (!this.hideNameCode){
               if (!this.name) {
                  this.$alert(this.name_no_null, '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
                  return
               }
               // 编码不能为空
               if (!this.number){
                  this.$alert(this.number_no_null, '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
                  return
               }
            }
            let url = system.addOrg
            let param = {
               name: this.name,
               number: this.number,
               remark: this.remark,
               hotelId: this.hotelId,
               companyId: this.companyId,         // 添加根节点
               parentId: this.id,                 // 添加二级节点
               isCreateVoice: this.isCreateVoice,
            }
            if (this.action === 'edit'){
               url = system.editOrg
               param.id = this.id
               delete param.companyId
               delete param.parentId
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.action === 'add' ? this.add_success : this.update_success,
                     type: 'success'
                  })
                  this.handleClose()
                  this.refreshLoadTree(this.parentId)
               }
            })
         },
         // 关闭弹窗
         handleClose(){
            this.isDisabled = false
            this.hideNameCode = false
            this.isRequired = false
            this.isShow = false
            this.resetForm()
         },
         // 删除组织
         handleDel(id, parentId){
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'warning'
            }).then(() => {
               let ids = []; ids.unshift(id)
               const param = { ids: ids.toString() }
               const url = system.delOrg
               this.$axios.post(url,param).then(res => {
                  if (res.success){
                     this.$message({
                        showClose: true,
                        message: this.del_success,
                        type: 'success'
                     })
                     parentId ? this.refreshLoadTree(parentId) : this.getOrg()
                     /*
                     * 如果当前行有被选中
                     * 删除数据后 取消当前选中
                     * */
                     this.oid && this.handleTableChange({id})
                  }
               }).catch((error) => {
                  this.$message.error(error)
               })
            }).catch(cancel => {
               console.log(cancel)
            })
         },
      },
      filters: {
         filterType(val){
            switch (val) {
               case "PLATFORM":
                  return val = "平台"
               case "TENANT":
                  return val = "租户"
               case "STORE":
                  return val = "商户"
            }
         }
      }
   }
</script>

<style lang="scss" scoped>
.left-round-box{
   padding: 30px;
   background: #fff;
}
</style>
